/**
 * @desc:
 * @date: 2022/3/8 下午3:26
 * @author xianwen.zeng@vlinkage.com
 */
import {makeAutoObservable} from "mobx";
import * as storages from "./storages";

function storeHome() {
    return makeAutoObservable({
        custom_trigger: false, //是否收起侧边栏
        custom_navId: 0, //顶部导航栏当前选中id
        custom_isMyPage: false, //是否显示个人中心
        custom_menuKey: 'account', //个人中心menu Key

        custom_sliderData: [], //侧边栏data
        custom_sliderMenu: [], //侧边栏menu key
        custom_sliderItem: '', //侧边栏item key

        dataLoading: false,
        breadArr: [],
        breadData: [],

        setMobxValue(key, value) {
            if (key === 'custom_menuKey') {
                this.custom_menuKey = value;
                storages.setCustomMenuKey(value);
            } else if (key === 'custom_isMyPage') {
                this.custom_isMyPage = value;
                storages.setCustomIsMyPage(value);
            } else if (key === 'custom_navId') {
                this.custom_navId = value;
                storages.setCustomNavId(value);
            } else if (key === 'custom_sliderMenu') {
                this.custom_sliderMenu = value;
                storages.setCustomSliderMenu(value);
            } else if (key === 'custom_sliderItem') {
                this.custom_sliderItem = value;
                storages.setCustomSliderItem(value);
            } else if (key === 'custom_sliderData') this.custom_sliderData = value;
            else if (key === 'custom_trigger') this.custom_trigger = !this.custom_trigger;
            else if (key === 'breadArr') this.breadArr = value;
            else if (key === 'breadData') this.breadData = value;
            else if (key === 'dataLoading') this.dataLoading = value;
        }
    });
}

export default storeHome();
