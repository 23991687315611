/**
 * @desc:
 * @date: 2022/3/4 下午4:10
 * @author xianwen.zeng@vlinkage.com
 */
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {runInAction} from 'mobx';
import {useNavigate, Outlet} from "react-router-dom";
import storeHome from "../indexAssets/js/storeHome";
import Header from "../Header";
import Footer from "../Footer";
import LOGO_IMG from "../../../static/images/logo.png";
import "../indexAssets/css/Layout.scss";
import * as storage from "../../../utils/storage";
import {
    Layout,
    Menu,
    YoutubeOutlined,
    ConsoleSqlOutlined,
    DesktopOutlined,
    BranchesOutlined,
    VideoCameraAddOutlined,
    VideoCameraOutlined,
    RedditOutlined,
    PlaySquareOutlined,
    UserOutlined,
    GoldOutlined,
    CopyrightOutlined,
    TrademarkOutlined,
    CameraOutlined,
    IeOutlined,
    BankOutlined,
    AppstoreAddOutlined,
    MenuOutlined,
    DiffOutlined,
    AppstoreOutlined,
    TeamOutlined,
    AudioOutlined,
    GlobalOutlined,
    DashboardOutlined,
    UserSwitchOutlined,
    NumberOutlined,
    UsergroupAddOutlined,
    FileSyncOutlined,
    FileTextOutlined,
    LineChartOutlined,
    AlertOutlined,
    SolutionOutlined,
    ExceptionOutlined,
    FileProtectOutlined,
    ExpandOutlined,
    MoreOutlined,
    PictureOutlined,
    AndroidOutlined,
    RocketOutlined,
    FlagOutlined,
    KeyOutlined,
    WalletOutlined,
    BarChartOutlined,
    FundOutlined,
    AreaChartOutlined,
    TrophyOutlined,
    CrownOutlined,
    MessageOutlined,
    SmileOutlined,
    SettingOutlined,
    ReadOutlined,
    LockOutlined
} from "../../../common";
import * as storages from "../indexAssets/js/storages";

const {Content, Sider} = Layout;
const {SubMenu} = Menu;

const LayoutSider = observer(() => {
    const navigate = useNavigate();
    const iconMenu = [
        0,
        <YoutubeOutlined/>,
        <ConsoleSqlOutlined/>,
        <DesktopOutlined/>,
        <BranchesOutlined/>,
        <VideoCameraAddOutlined/>,
        <VideoCameraOutlined/>,
        <RedditOutlined/>,
        <PlaySquareOutlined/>, //9
        <UserOutlined/>,
        <GoldOutlined/>,
        <CopyrightOutlined/>,
        <TrademarkOutlined/>,
        <CameraOutlined/>,
        <IeOutlined/>,
        <BankOutlined/>,
        <AppstoreAddOutlined/>,
        <MenuOutlined/>,
        <DiffOutlined/>,
        <AppstoreOutlined/>,
        <TeamOutlined/>,
        <AudioOutlined/>,
        <GlobalOutlined/>,
        <DashboardOutlined />,
        <UserSwitchOutlined/>,
        <NumberOutlined/>,
        <UsergroupAddOutlined/>,
        <UserOutlined/>,
        <FileSyncOutlined/>,
        <FileTextOutlined/>,
        <LineChartOutlined/>,
        <AlertOutlined/>,
        <SolutionOutlined/>,
        <ExceptionOutlined/>,
        <FileProtectOutlined/>,
        <ExpandOutlined/>,
        <MoreOutlined/>,
        <PictureOutlined/>,
        <AndroidOutlined/>,
        <MenuOutlined/>,
        <RocketOutlined/>,
        <FlagOutlined/>,
        <LockOutlined/>,
        <KeyOutlined/>,
        <WalletOutlined/>,
        <TeamOutlined/>,
        <YoutubeOutlined/>,
        <VideoCameraAddOutlined/>,
        <UserOutlined/>,//48
        <BarChartOutlined/>,
        <VideoCameraOutlined/>,
        <LineChartOutlined/>,
        <FundOutlined/>, //52
        <AreaChartOutlined/>,//53
        <CrownOutlined />,
        <TrophyOutlined />,
        <MessageOutlined />,//56
        <SmileOutlined />,
        <FileTextOutlined />,
        <ReadOutlined />,
        <SettingOutlined />,
    ];

    useEffect(() => {
        let siderMenu = storages.getCustomSliderMenu();
        onChangeStore('custom_sliderMenu', siderMenu || []);
        onChangeStore('custom_sliderItem', storages.getCustomSliderItem());
    }, []);

    function onChangeStore(name, data) {
        storeHome.setMobxValue(name, data);
    }

    function onPushMenu(key, isMenu) {
        let menuArr = isMenu ? [] : storeHome.custom_sliderMenu;
        if (isMenu) menuArr = (storeHome.custom_sliderMenu.length && storeHome.custom_sliderMenu[0] === key) ? [] : [key];
        else runInAction(() => menuArr[1] = storeHome.custom_sliderMenu[1] === key ? storeHome.custom_sliderMenu.splice(1, 1) : key);
        onChangeStore('custom_sliderMenu', menuArr);
    }

    function onPressMenuItem(item) {
        onChangeStore('custom_sliderItem', item.key);
        navigate(item.key)
    }

    return (
        <Sider
            className="applayout-slider"
            collapsed={storeHome.custom_trigger}
            collapsible
            style={{'backgroundColor': '#EFEEEE'}}
            theme={'light'}
            trigger={null}>
            <div className="applayout-logo">
                <img src={LOGO_IMG} alt="logo"/>
                <span>{!storeHome.custom_trigger && JSON.parse(storage.get('roleInfo'))?.name}</span>
            </div>
            <Menu
                theme="light"
                mode="inline"
                className="slider-menu"
                openKeys={storeHome.custom_sliderMenu.slice()}
                selectedKeys={[storeHome.custom_sliderItem]}
                onClick={(item) => onPressMenuItem(item)}>
                {storeHome.custom_sliderData[storeHome.custom_navId - 1]?.children?.map(item =>
                    item.children ?
                        <SubMenu
                            key={item.key}
                            title={item.title}
                            icon={iconMenu[item.icon]}
                            onTitleClick={(menu) => onPushMenu(menu.key, true)}>
                            {item.children.map(i => i.children ?
                                <SubMenu
                                    key={i.key}
                                    title={i.title}
                                    icon={iconMenu[i.icon]}
                                    onTitleClick={(menu) => onPushMenu(menu.key)}>
                                    {i.children.map(j => <Menu.Item key={j.key} icon={iconMenu[j.icon]}>{j.title}</Menu.Item>)}
                                </SubMenu> : <Menu.Item key={i.key} icon={iconMenu[i.icon]}>{i.title}</Menu.Item>)}
                        </SubMenu> :
                        <Menu.Item key={item.key} icon={iconMenu[item.icon]}>{item.title}</Menu.Item>)}
            </Menu>
        </Sider>
    );
});

const AppLayout = () => {
    return (
        <Layout className="appLayout-content">
            <LayoutSider/>
            <Layout className="appLayout-right-box">
                <Header/>
                <Content className="appLayout-right-body"><Outlet/></Content>
                <Footer/>
            </Layout>
        </Layout>
    );
};

export default observer(AppLayout);
