/**
 * @desc: 主页顶部导航栏
 * @date: 2022/1/4 下午5:13
 * @author xianwen.zeng@vlinkage.com
 */
import React, {useEffect, useRef} from 'react';
import {Menu, Dropdown, MenuOutlined, Modal, Message} from "../../../common";
import {observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import storeHome from "../indexAssets/js/storeHome";
import api from "../../../api/api";
import "../indexAssets/css/Layout.scss";
import * as cookie from "../../../utils/cookie";
import * as storages from "../indexAssets/js/storages";
import LOGO from "../../../static/images/logo.png";
import {runInAction} from "mobx";
import * as storage from "../../../utils/storage";

const Header = ({isHome}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const menuSize = useRef(0);
    const personalItems = [{label: '我的账户', key: 'account'}, {label: '修改密码', key: 'password'}, {
        label: '退出',
        key: 'out'
    }];

    useEffect(() => {
        if (!storeHome.custom_sliderData.length) getSlider();
        const navIdIsSliderItem = storages.getCustomSliderItem();
        const pathName = location.pathname;
        if (pathName.indexOf('detail') === -1 && navIdIsSliderItem !== null) navigate(navIdIsSliderItem);
        else if (pathName !== '/') {
            let pathArr = pathName.substr(1, pathName.length).split('/');
            if (pathArr[0]) getSlider(pathArr)
        }
        const navId = storages.getCustomNavId();
        const isMyPage = storages.getCustomIsMyPage();
        if (isHome) {
            onChangeStore('custom_navId', isMyPage === null ? 0 : navId);
            onChangeStore('custom_isMyPage', isMyPage === null ? storeHome.custom_isMyPage : isMyPage);
            if (isMyPage) onChangeStore('custom_menuKey', storages.getCustomMenuKey());
        } else onChangeStore('custom_navId', navId === null ? storeHome.custom_navId : navId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSlider(pathArr) {
        const {data} = await api.getData('oauth/user_menu');
        if (data.code === 0) {
            onChangeStore('custom_sliderData', data.data);
            if (pathArr) {
                for (let i = 0; i < storeHome.custom_sliderData.length; i++) {
                    if (pathArr[0] === storeHome.custom_sliderData[i].key) onChangeNav(i + 1)
                }
            }
        } else return Message.error(data.msg || '服务器错误');
    }

    function onChangePersonal(key) {
        if (key === 'out') {
            Modal.confirm({
                title: '提示',
                content: '退出将回到登录页面、你确定要退出吗？',
                onOk() {
                    onChangeStore('custom_isMyPage', false);
                    cookie.clear();
                    storages.clearAll();
                    storage.clearSession();
                    navigate('/index/login', {replace: true});
                }
            });
        } else {
            onChangeStore('custom_sliderMenu', []);
            onChangeStore('custom_sliderItem', '');
            onChangeStore('custom_navId', storeHome.custom_sliderData.length + 1);
            onChangeStore('custom_isMyPage', true);
            onChangeStore('custom_menuKey', key);
            navigate('/');
        }
    }

    function onChangeNav(index) {
        if (storages.getCustomNavId() === index) return;
        //清除侧边栏刷新状态保持 start
        onChangeStore('custom_sliderMenu', []);
        onChangeStore('custom_sliderItem', '');
        //清除侧边栏刷新状态保持 end
        onChangeStore('custom_navId', index);
        if (index === 0 || index === storeHome.custom_sliderData.length + 1) {
            onChangeStore('custom_isMyPage', index === storeHome.custom_sliderData.length + 1);
            navigate('/');
        } else getFirstUrl(storeHome.custom_sliderData[index - 1]);
    }

    function getFirstUrl(data) {
        if (data.children) {
            menuSize.current = 0;
            getFirstUrl(data.children);
        } else if (data[0].children) {
            menuSize.current++;
            onFirstMenu(data[0].key, menuSize.current === 1);
            getFirstUrl(data[0].children);
        } else {
            menuSize.current = 0;
            onChangeStore('custom_sliderItem', data[0].key);
            navigate(data[0].url);
        }
    }

    function onFirstMenu(key, isMenu) {
        let menuArr = isMenu ? [] : storeHome.custom_sliderMenu;
        if (isMenu) menuArr = (storeHome.custom_sliderMenu.length && storeHome.custom_sliderMenu[0] === key) ? [] : [key];
        else runInAction(() => menuArr[1] = storeHome.custom_sliderMenu[1] === key ? storeHome.custom_sliderMenu.splice(1, 1) : key);
        onChangeStore('custom_sliderMenu', menuArr);
    }

    function onChangeStore(name, data) {
        storeHome.setMobxValue(name, data);
    }

    return (
        <div className="header-content">
            <div className="header-left-box">
                {isHome ?
                    <img src={LOGO} alt="Vlinkage" onClick={() => onChangeStore('custom_isMyPage', false)}/> : <>
                        <MenuOutlined className="header-left-trigger" onClick={() => onChangeStore('custom_trigger')}/>
                        <span>Vlinkage后台管理系统</span></>}
            </div>
            <div className="header-right-box">
                <span className={Number(storeHome.custom_navId) === 0 ? 'header-right-check' : ''}
                      onClick={() => onChangeNav(0)}>首页</span>
                {storeHome.custom_sliderData.map((item, index) => (
                    <span key={index}
                          className={Number(storeHome.custom_navId) === index + 1 ? 'header-right-check' : ''}
                          onClick={() => onChangeNav(index + 1)}>{item.name}</span>))}
                <Dropdown placement={'bottom'}
                          overlay={<Menu items={personalItems} onClick={(e) => onChangePersonal(e.key)}/>}>
                    <span
                        className={Number(storeHome.custom_navId) === storeHome.custom_sliderData.length + 1 ? 'header-right-check' : ''}
                        onClick={() => onChangeNav(storeHome.custom_sliderData.length + 1)}>个人中心</span>
                </Dropdown>
                <div className={'header-right-line'} style={{left: storeHome.custom_navId * 92 + 'px'}}/>
            </div>
        </div>
    );
};

export default observer(Header);
