/**
* @desc:
* @date: 2022/3/8 下午3:10
* @author xianwen.zeng@vlinkage.com
*/
//主页是否显示个人中心
export function setCustomIsMyPage(data) {
    sessionStorage.setItem('custom_isMyPage', JSON.stringify(data));
}
export function getCustomIsMyPage() {
    return JSON.parse(sessionStorage.getItem('custom_isMyPage'));
}

//页面顶部nav状态Id
export function setCustomNavId(data) {
    sessionStorage.setItem('custom_navId', JSON.stringify(data));
}
export function getCustomNavId() {
    return JSON.parse(sessionStorage.getItem('custom_navId'));
}

//页面顶部nav状态个人中心key
export function setCustomMenuKey(data) {
    sessionStorage.setItem('custom_menuKey', JSON.stringify(data));
}
export function getCustomMenuKey() {
    return JSON.parse(sessionStorage.getItem('custom_menuKey'));
}

//页面侧边栏sider Menu Key
export function setCustomSliderMenu(data) {
    sessionStorage.setItem('custom_sliderMenu', JSON.stringify(data));
}
export function getCustomSliderMenu() {
    return JSON.parse(sessionStorage.getItem('custom_sliderMenu'));
}

//页面侧边栏sider Item Key
export function setCustomSliderItem(data) {
    sessionStorage.setItem('custom_sliderItem', JSON.stringify(data));
}
export function getCustomSliderItem() {
    return JSON.parse(sessionStorage.getItem('custom_sliderItem'));
}

export function clearAll() {
    return localStorage.clear();
}
