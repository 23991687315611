/**
 * @desc:
 * @date: 2022/3/7 下午3:38
 * @author xianwen.zeng@vlinkage.com
 */
import React, {Suspense} from "react";
import {Routes, Route} from "react-router-dom";
import * as routers from "./router";
import AppLayout from "./pages/indexPages/AppLayout";
import "antd/dist/antd.min.css";
import "../src/static/theme/global.scss"

function App() {
    return (
        <Suspense fallback={<></>}>{/*fallback 属性接受任何在组件加载过程中你想展示的 React 元素 懒加载组件是 <React.Suspense> 支持的唯一用例：*/}
            <Routes> {/* 只匹配一条路由*/}
                <Route path='meta' element={<AppLayout/>}>
                    {routers.metaRouter.map((meta, index) => <Route key={index} path={meta.path} element={meta.component}/>)}
                </Route>
                <Route path='xunyee' element={<AppLayout/>}>
                    {routers.xunyeeRouter.map((xunyee, index) => <Route key={index} path={xunyee.path} element={xunyee.component}/>)}
                </Route>
                <Route path='vdata' element={<AppLayout/>}>
                    {routers.vdataRouter.map((vdata, index) => <Route key={index} path={vdata.path} element={vdata.component}/>)}
                </Route>
                <Route path='brand' element={<AppLayout/>}>
                    {routers.brandRouter.map((brand, index) => <Route key={index} path={brand.path} element={brand.component}/>)}
                </Route>
                <Route path='power' element={<AppLayout/>}>
                    {routers.powerRouter.map((power, index) => <Route key={index} path={power.path} element={power.component}/>)}
                </Route>
                {routers.indexRouter.map((home, index) => <Route key={index} path={home.path} element={home.component}/>)}
            </Routes>
        </Suspense>
    );
}

export default App;
