/**
 * @desc:
 * @date: 2021/4/20 上午10:17
 * @author xianwen.zeng@vlinkage.com
 */

export const set = (key, value) => {
    return localStorage.setItem(key, value);
};

export const get = (key) => {
    return localStorage.getItem(key);
};

export const rm = (key) => {
    return localStorage.removeItem(key);
};

export const clear = () => {
    return localStorage.clear();
};

export const setSession = (key, value) => {
    return sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSession = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
};

export const rmSession = (key) => {
    return sessionStorage.removeItem(key);
};

export const clearSession = () => {
    return sessionStorage.clear();
};
