/**
* @desc:
* @date: 2021/4/20 上午11:05
* @author xianwen.zeng@vlinkage.com
*/
export const set = (key, value) => {
    return document.cookie = `${key}=${value};path=/`;
};

export const get = (key) => {
    let name = key + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
};
export const rm = (key) => {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = get(key);
    if (cval != null)
        document.cookie = key + "=" + cval + ";expires=" + exp.toGMTString() + ";path=/";
};
//清除所有cookie函数
export const clear = () => {
    // eslint-disable-next-line no-useless-escape
    let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (let i = keys.length; i--;)
            document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() + ";path=/";
    }
};
