// import React from 'react';
// import {createRoot} from 'react-dom/client';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {BrowserRouter} from "react-router-dom";
// import {ConfigProvider, zhCN} from "./common";
//
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<ConfigProvider locale={zhCN}><BrowserRouter><App/></BrowserRouter></ConfigProvider>);
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
//

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider, zhCN} from "./common";

ReactDOM.render(
    // <React.StrictMode>
    <ConfigProvider locale={zhCN}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ConfigProvider>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
