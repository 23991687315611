/**
 * @desc:
 * @date: 2021/4/20 上午11:20
 * @author xianwen.zeng@vlinkage.com
 */
import React, {lazy} from 'react';

//meta
const PageYSGLDsj = lazy(() => import('../pages/metaPages/PageYSGL/PageDsj/PageYSGLDsj'));
const PageDsjDetail = lazy(() => import('../pages/metaPages/PageYSGL/PageDsj/PageYSGLDsj/PageDsjDetail'));
const PageDsjDefect = lazy(() => import('../pages/metaPages/PageYSGL/PageDsj/PageDsjDefect'));
const PageYSGLZy = lazy(() => import('../pages/metaPages/PageYSGL/PageZy/PageYSGLZy'));
const PageZyDetail = lazy(() => import('../pages/metaPages/PageYSGL/PageZy/PageYSGLZy/PageZyDetail'));
const PageZyDefect = lazy(() => import('../pages/metaPages/PageYSGL/PageZy/PageZyDefect'));
const PageYSGLKt = lazy(() => import('../pages/metaPages/PageYSGL/PageYSGLKt'));
const PageYSGLDy = lazy(() => import('../pages/metaPages/PageYSGL/PageYSGLDy'));
const PageMTGLWz = lazy(() => import('../pages/metaPages/PageMTGL/PageMTGLWz'));
const PageMTGLTv = lazy(() => import('../pages/metaPages/PageMTGL/PageMTGLTv'));
const PagePPGLPp = lazy(() => import('../pages/metaPages/PagePPGL/PagePPGLPp'));
const PagePpDetail = lazy(() => import('../pages/metaPages/PagePPGL/PagePPGLPp/PagePpDetail'));
const PagePPGLCp = lazy(() => import('../pages/metaPages/PagePPGL/PagePPGLCp'));
const PagePPGLRm = lazy(() => import('../pages/metaPages/PagePPGL/PagePPGLRm'));
const PagePPGLYr = lazy(() => import('../pages/metaPages/PagePPGL/PagePPGLYr'));
const PagePPGLYrDetail = lazy(() => import('../pages/metaPages/PagePPGL/PagePPGLYr/PageYrDetail'));
const PageLMGLFl = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLFl'));
const PageLMGLTc = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLTc'));
const PageLMGLYy = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLYy'));
const PageLMGLZt = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLZt'));
const PageLMGLGj = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLGj'));
const PageLMGLJs = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLJs'));
const PageLMGLHt = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLHt'));
const PageLMGLZf = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLZf'));
const PageLMGLZl = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLZl'));
const PageLMGLKs = lazy(() => import('../pages/metaPages/PageLMGL/PageLMGLKs'));
const PageYRGL = lazy(() => import('../pages/metaPages/PageYRGL'));
const PageYRDetail = lazy(() => import('../pages/metaPages/PageYRGL/PageYRDetail'));

//vdata
const PageDsjRank = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjRank'));
const PageDsjMediaBaiduTop = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaBaiduTop'));
const PageDsjMediaBaiduNew = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaBaiduNew'));
const PageDsjMediaBaiduIndex = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaBaiduIndex'));
const PageDsjMediaTrend = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaTrend'));
const PageDsjMediaDouban = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaDouban'));
const PageDsjMediaTieba = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaTieba'));
const PageDsjMediaWeibo = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaWeibo'));
const PageDsjMediaBuluo = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjMedia/PageDsjMediaBuluo'));
const PageDsjViewTencent = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewTencent'));
const PageDsjViewIqiyi = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewIqiyi'));
const PageDsjViewYouku = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewYouku'));
const PageDsjViewBilibili = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewBilibili'));
const PageDsjViewMangguo = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewMangguo'));
const PageDsjViewSohu = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewSohu'));
const PageDsjViewLetv = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjView/PageDsjViewLetv'));
const PageDsjChart = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjChart'));
const PageDsjExcel = lazy(() => import('../pages/vdataPages/PageDsj/PageDsjExcel'));
const PageZyRank = lazy(() => import('../pages/vdataPages/PageZy/PageZyRank'));
const PageZyMediaBaiduTop = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaBaiduTop'));
const PageZyMediaBaiduIndex = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaBaiduIndex'));
const PageZyMediaTrend = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaTrend'));
const PageZyMediaDouban = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaDouban'));
const PageZyMediaTieba = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaTieba'));
const PageZyMediaWeibo = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaWeibo'));
const PageZyMediaBuluo = lazy(() => import('../pages/vdataPages/PageZy/PageZyMedia/PageZyMediaBuluo'));
const PageZyViewTencent = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewTencent'));
const PageZyViewIqiyi = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewIqiyi'));
const PageZyViewYouku = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewYouku'));
const PageZyViewBilibili = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewBilibili'));
const PageZyViewMangguo = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewMangguo'));
const PageZyViewSohu = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewSohu'));
const PageZyViewLetv = lazy(() => import('../pages/vdataPages/PageZy/PageZyView/PageZyViewLetv'));
const PageZyChart = lazy(() => import('../pages/vdataPages/PageZy/PageZyChart'));
const PageZyExcel = lazy(() => import('../pages/vdataPages/PageZy/PageZyExcel'));
const PagePersonRank = lazy(() => import('../pages/vdataPages/PagePerson/PagePersonRank'));
const PagePersonMediaDouban = lazy(() => import('../pages/vdataPages/PagePerson/PagePersonMedia/PagePersonMediaDouban'));
const PagePersonMediaTieba = lazy(() => import('../pages/vdataPages/PagePerson/PagePersonMedia/PagePersonMediaTieba'));
const PagePersonMediaWeibo = lazy(() => import('../pages/vdataPages/PagePerson/PagePersonMedia/PagePersonMediaWeibo'));
const PagePersonChart = lazy(() => import('../pages/vdataPages/PagePerson/PagePersonChart'));
const PagePersonExcel = lazy(() => import('../pages/vdataPages/PagePerson/PagePersonExcel'));

//xunyee
const PageYHGLUser = lazy(() => import('../pages/xunyeePages/PageYHGL/PageYHGLUser'));
const PageYHGLUnid = lazy(() => import('../pages/xunyeePages/PageYHGL/PageYHGLUnid'));
const PageDDGLOrder = lazy(() => import('../pages/xunyeePages/PageDDGL/PageDDGLOrder'));
const PageDDGLCoin = lazy(() => import('../pages/xunyeePages/PageDDGL/PageDDGLCoin'));
const PageTPGLAd = lazy(() => import('../pages/xunyeePages/PageTPGL/PageTPGLAd'));
const PageTPGLType = lazy(() => import('../pages/xunyeePages/PageTPGL/PageTPGLType'));
// const PageTPGLPic = lazy(() => import('../pages/xunyeePages/PageTPGL/PageTPGLPic'));
// const PagePicDetail = lazy(() => import('../pages/xunyeePages/PageTPGL/PageTPGLPic/PagePicDetail'));
const PageAPPClass = lazy(() => import('../pages/xunyeePages/PageAPP/PageAPPClass'));
const PageAPPVersion = lazy(() => import('../pages/xunyeePages/PageAPP/PageAPPVersion'));
const PageTSJYReport = lazy(() => import('../pages/xunyeePages/PageTSJY/PageTSJYReport'));
const PageTSJYUser = lazy(() => import('../pages/xunyeePages/PageTSJY/PageTSJYUser'));
const PageTSJYPorp = lazy(() => import('../pages/xunyeePages/PageTSJY/PageTSJYPorp'));
const PageDTGLIndex = lazy(() => import('../pages/xunyeePages/PageDTGL/PageDTGLIndex'));
const PageDTPLIndex = lazy(() => import('../pages/xunyeePages/PageDTGL/PageDTPLIndex'));
const PageXCGLIndex = lazy(() => import('../pages/xunyeePages/PageXCGL/PageXCGLIndex'));
const PageVipPower = lazy(() => import('../pages/xunyeePages/PageVip/PageVipPower'));
const PageWDGLRevive = lazy(() => import('../pages/xunyeePages/PageWDGL/PageWDGLRevive'));
const PageWDGLWar = lazy(() => import('../pages/xunyeePages/PageWDGL/PageWDGLWar'));
const PageWDGLTk = lazy(() => import('../pages/xunyeePages/PageWDGL/PageWDGLTk'));
const PageWDGLTm = lazy(() => import('../pages/xunyeePages/PageWDGL/PageWDGLTm'));

//brand
const PageActivity = lazy(() => import('../pages/brandPages/PageActivity'));
const PageActivityDetail = lazy(() => import('../pages/brandPages/PageActivity/PageActivityDetail'));

//power
const PageUser = lazy(() => import('../pages/powerPages/PageUser'));
const PageRole = lazy(() => import('../pages/powerPages/PageRole'));
const PageMiss = lazy(() => import('../pages/powerPages/PageMiss'));

//index
const PageLogin = lazy(() => import('../pages/indexPages/PageLogin'));
const PageHome = lazy(() => import('../pages/indexPages/PageHome'));
const PageError = lazy(() => import('../pages/PageResult/PageError'));

export const metaRouter = [
    {title: '电视剧缺失列表', path: 'ysgl/dsj/defect', component: <PageDsjDefect/>},
    {title: '电视剧详情', path: 'ysgl/dsj/dsj_detail', component: <PageDsjDetail/>},
    {title: '电视剧', path: 'ysgl/dsj/index', component: <PageYSGLDsj/>},
    {title: '综艺缺失列表', path: 'ysgl/zy/defect', component: <PageZyDefect/>},
    {title: '综艺详情', path: 'ysgl/zy/zy_detail', component: <PageZyDetail/>},
    {title: '综艺', path: 'ysgl/zy/index', component: <PageYSGLZy/>},
    {title: '电影', path: 'ysgl/dy', component: <PageYSGLDy/>},
    {title: '卡通', path: 'ysgl/kt', component: <PageYSGLKt/>},
    {title: '艺人详情', path: 'yrgl/detail', component: <PageYRDetail/>},
    {title: '艺人', path: 'yrgl', component: <PageYRGL/>},
    {title: '网站', path: 'mtgl/wz', component: <PageMTGLWz/>},
    {title: '电视台', path: 'mtgl/tv', component: <PageMTGLTv/>},
    {title: '品牌详情', path: 'ppgl/pp/detail', component: <PagePpDetail/>},
    {title: '品牌', path: 'ppgl/pp', component: <PagePPGLPp/>},
    {title: '产品', path: 'ppgl/cp', component: <PagePPGLCp/>},
    {title: '热门', path: 'ppgl/rm', component: <PagePPGLRm/>},
    {title: '品牌管理艺人详情', path: 'ppgl/yr/detail', component: <PagePPGLYrDetail/>},
    {title: '品牌管理艺人', path: 'ppgl/yr', component: <PagePPGLYr/>},
    {title: '电视剧分类', path: 'lmgl/fl', component: <PageLMGLFl/>},
    {title: '电视剧题材', path: 'lmgl/tc', component: <PageLMGLTc/>},
    {title: '语言', path: 'lmgl/yy', component: <PageLMGLYy/>},
    {title: '状态', path: 'lmgl/zt', component: <PageLMGLZt/>},
    {title: '国家', path: 'lmgl/gj', component: <PageLMGLGj/>},
    {title: '角色', path: 'lmgl/js', component: <PageLMGLJs/>},
    {title: '话题', path: 'lmgl/ht', component: <PageLMGLHt/>},
    {title: '综艺分类', path: 'lmgl/zf', component: <PageLMGLZf/>},
    {title: '综艺类型', path: 'lmgl/zl', component: <PageLMGLZl/>},
    {title: '艺人角色', path: 'lmgl/ks', component: <PageLMGLKs/>},
    {title: '错误页', path: '*', component: <PageError/>}
];

export const vdataRouter = [
    {title: '电视剧排名图表', path: 'dsj/rank', component: <PageDsjRank/>},
    {title: '电视剧图表', path: 'dsj/chart', component: <PageDsjChart/>},
    {title: '电视剧百度风云榜', path: 'dsj/media/baidutop', component: <PageDsjMediaBaiduTop/>},
    {title: '电视剧百度新闻', path: 'dsj/media/baidunew', component: <PageDsjMediaBaiduNew/>},
    {title: '电视剧百度指数', path: 'dsj/media/baiduindex', component: <PageDsjMediaBaiduIndex/>},
    {title: '电视剧360趋势', path: 'dsj/media/sotrend', component: <PageDsjMediaTrend/>},
    {title: '电视剧豆瓣', path: 'dsj/media/douban', component: <PageDsjMediaDouban/>},
    {title: '电视剧微博', path: 'dsj/media/weibo', component: <PageDsjMediaWeibo/>},
    {title: '电视剧贴吧', path: 'dsj/media/tieba', component: <PageDsjMediaTieba/>},
    {title: '电视剧部落', path: 'dsj/media/buluo', component: <PageDsjMediaBuluo/>},
    {title: '电视剧腾讯', path: 'dsj/view/tencent', component: <PageDsjViewTencent/>},
    {title: '电视剧爱奇艺', path: 'dsj/view/iqiyi', component: <PageDsjViewIqiyi/>},
    {title: '电视剧优酷', path: 'dsj/view/youku', component: <PageDsjViewYouku/>},
    {title: '电视剧哔哩哔哩', path: 'dsj/view/bilibili', component: <PageDsjViewBilibili/>},
    {title: '电视剧芒果TV', path: 'dsj/view/mangguo', component: <PageDsjViewMangguo/>},
    {title: '电视剧搜狐', path: 'dsj/view/sohu', component: <PageDsjViewSohu/>},
    {title: '电视剧乐视', path: 'dsj/view/letv', component: <PageDsjViewLetv/>},
    {title: '电视剧报表', path: 'dsj/excel', component: <PageDsjExcel/>},
    {title: '综艺排名图表', path: 'zy/rank', component: <PageZyRank/>},
    {title: '综艺图表', path: 'zy/chart', component: <PageZyChart/>},
    {title: '综艺百度风云榜', path: 'zy/media/baidutop', component: <PageZyMediaBaiduTop/>},
    {title: '综艺百度指数', path: 'zy/media/baiduindex', component: <PageZyMediaBaiduIndex/>},
    {title: '综艺360趋势', path: 'zy/media/sotrend', component: <PageZyMediaTrend/>},
    {title: '综艺豆瓣', path: 'zy/media/douban', component: <PageZyMediaDouban/>},
    {title: '综艺微博', path: 'zy/media/weibo', component: <PageZyMediaWeibo/>},
    {title: '综艺贴吧', path: 'zy/media/tieba', component: <PageZyMediaTieba/>},
    {title: '综艺部落', path: 'zy/media/buluo', component: <PageZyMediaBuluo/>},
    {title: '综艺腾讯', path: 'zy/view/tencent', component: <PageZyViewTencent/>},
    {title: '综艺爱奇艺', path: 'zy/view/iqiyi', component: <PageZyViewIqiyi/>},
    {title: '综艺优酷', path: 'zy/view/youku', component: <PageZyViewYouku/>},
    {title: '综艺哔哩哔哩', path: 'zy/view/bilibili', component: <PageZyViewBilibili/>},
    {title: '综艺芒果TV', path: 'zy/view/mangguo', component: <PageZyViewMangguo/>},
    {title: '综艺搜狐', path: 'zy/view/sohu', component: <PageZyViewSohu/>},
    {title: '综艺乐视', path: 'zy/view/letv', component: <PageZyViewLetv/>},
    {title: '综艺报表', path: 'zy/excel', component: <PageZyExcel/>},
    {title: '艺人排名图表', path: 'person/rank', component: <PagePersonRank/>},
    {title: '艺人图表', path: 'person/chart', component: <PagePersonChart/>},
    {title: '艺人报表', path: 'person/excel', component: <PagePersonExcel/>},
    {title: '艺人豆瓣', path: 'person/media/douban', component: <PagePersonMediaDouban/>},
    {title: '艺人微博', path: 'person/media/weibo', component: <PagePersonMediaWeibo/>},
    {title: '艺人贴吧', path: 'person/media/tieba', component: <PagePersonMediaTieba/>},
    {title: '艺人播放量', path: 'person/view', component: <PageDDGLOrder/>},
    {title: '错误页', path: '*', component: <PageError/>}
];

export const xunyeeRouter = [
    {title: '用户列表', path: 'yhgl/user', component: <PageYHGLUser/>},
    {title: '用户unionid列表', path: 'yhgl/unid', component: <PageYHGLUnid/>},
    {title: '订单列表', path: 'ddgl/order', component: <PageDDGLOrder/>},
    {title: '付费列表', path: 'ddgl/coin', component: <PageDDGLCoin/>},
    {title: '广告列表', path: 'tpgl/ad', component: <PageTPGLAd/>},
    // {title: '图片详情', exact: true, path: 'tpgl/pic/pic_detail', component: <PagePicDetail/>},
    // {title: '图片列表', exact: true, path: 'tpgl/pic', component: <PageTPGLPic/>},
    {title: '图片类型', path: 'tpgl/type', component: <PageTPGLType/>},
    {title: 'app分类管理', path: 'app/class', component: <PageAPPClass/>},
    {title: 'app版本管理', path: 'app/version', component: <PageAPPVersion/>},
    {title: '举报动态', path: 'tsjy/report', component: <PageTSJYReport/>},
    {title: '意见反馈管理', path: 'tsjy/porp', component: <PageTSJYPorp/>},
    {title: '举报用户', path: 'tsjy/user', component: <PageTSJYUser/>},
    {title: '动态列表', path: 'dtgl/index', component: <PageDTGLIndex/>},
    {title: '动态评论', path: 'dtpl/index', component: <PageDTPLIndex/>},
    {title: '行程列表', path: 'xcgl/index', component: <PageXCGLIndex/>},
    {title: '会员权益', path: 'vip/hyqy', component: <PageVipPower/>},
    {title: '复活卡', path: 'wdgl/fhk', component: <PageWDGLRevive/>},
    {title: '战力', path: 'wdgl/sys', component: <PageWDGLWar/>},
    {title: '题库', path: 'wdgl/tk', component: <PageWDGLTk/>},
    {title: '题目', path: 'wdgl/tm', component: <PageWDGLTm/>},
    {title: '错误页', path: '*', component: <PageError/>}
];

export const brandRouter = [
    {title: '品牌抽奖详情', path: 'activity/detail', component: <PageActivityDetail/>},
    {title: '品牌抽奖', path: 'activity', component: <PageActivity/>},
    {title: '错误页', path: '*', component: <PageError/>}
];

export const powerRouter = [
    {title: '账户列表', path: 'qxgl/user', component: <PageUser/>},
    {title: '角色列表', path: 'qxgl/role', component: <PageRole/>},
    {title: '权限列表', path: 'qxgl/miss', component: <PageMiss/>},
    {title: '错误页', path: '*', component: <PageError/>}
];

export const indexRouter = [
    {title: '登录', path: 'index/login', component: <PageLogin/>},
    {title: '主页', path: '/', component: <PageHome/>},
    {title: '错误页', path: '*', component: <PageError/>}
];
