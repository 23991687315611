/**
 * @desc:
 * @date: 2021/4/20 上午10:17
 * @author xianwen.zeng@vlinkage.com
 */
// export const baseUrl =  "http://172.16.6.80:8081/";
// export const baseUrl =  "http://47.99.116.175:9000/";
export const baseUrl =  "http://admin.api.vlinkage.com/";
// export const baseUrl =  "http://test.admin.api.vlinkage.com/";
// export const baseUrl =  "http://172.16.6.117:8081/";
// export const baseUrl =  "http://localhost:8081/";
export const encodeAjaxData =  false;
