/**
 * @desc: 主页底部信息栏
 * @date: 2022/3/7 下午5:47
 * @author xianwen.zeng@vlinkage.com
 */
import React from "react";
import {Divider} from "../../../common";
import "../indexAssets/css/Layout.scss";

const Footer = ({isHome}) => {
    return <div className="fotter-content" style={{background: isHome ? '#F1F2F5' : '#FFF'}}>
        <span>Powered by Vlinkage. since 2012. 沪ICP备09086024号-1.</span>
        {!isHome && <div>
            <a href="https://weibo.com/vlink?refer_flag=0000015010_&from=feed&loc=avatar" rel="noopener noreferrer" target="_blank">Vlinkage微博</a>
            <Divider type={'vertical'} className="fotter-line"/>
            <a href="http://www.vlinkage.com/" rel="noopener noreferrer" target="_blank">Vlinkage官网</a>
            <Divider type={'vertical'} className="fotter-line"/>
            <a href="https://www.xunyee.cn/" rel="noopener noreferrer" target="_blank">寻艺官网</a>
        </div>}
    </div>
};

export default Footer;
