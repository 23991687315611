import {POST, GET} from "./ajax";

//获取寻艺图片类型列表
function getXunyeePicType(current, size) {
    return GET(`pictype?current=${current}&size=${size}`);
}

//修改寻艺图片类型
function setXunyeePicType(id, name, orderby, enabled) {
    return POST(`pictype`, {id, name, orderby, enabled});
}

//获取寻艺图片类型列表
function getXunyeePic(current, size, title) {
    return GET(
        `pic?current=${current}&size=${size}${title ? "&title=" + title : ""}`
    );
}

//获取寻艺图片详情
function getXunyeePicDetail(id) {
    return GET(`pic/${id}`);
}

//修改寻艺图片详情
function setXunyeePicDetail(
    id,
    title,
    pic,
    sequence,
    typeId,
    startTime,
    finishTime,
    enabled_5,
    enabled_6,
    url,
    memo
) {
    return POST(`pic`, {
        id,
        title,
        pic,
        sequence,
        typeId,
        startTime,
        finishTime,
        enabled_5,
        enabled_6,
        url,
        memo
    });
}

//获取寻艺广告列表
function getXunyeeAd(current, size, title) {
    return GET(
        `ad?current=${current}&size=${size}${title ? "&title=" + title : ""}`
    );
}

//修改寻艺广告
function setXunyeeAd(
    id,
    title,
    sequence,
    adunit,
    startTime,
    finishTime,
    memo,
    enabled_5,
    enabled_6
) {
    return POST(`ad`, {
        id,
        title,
        sequence,
        adunit,
        startTime,
        finishTime,
        memo,
        enabled_5,
        enabled_6
    });
}

//获取电视剧图表
function getTeleplayChart(current, size, date, teleplayName) {
    return GET(
        `/report_teleplay?current=${current}&size=${size}${
            date ? "&date=" + date : ""
        }${teleplayName ? "&teleplayName=" + teleplayName : ""}`
    );
}

//获取综艺图表
function getZyChart(current, size, date) {
    return GET(
        `/report_zy?current=${current}&size=${size}${
            date ? "&date=" + date : ""
        }`
    );
}

function setData(path, params, isUpload) {
    return POST(`${path}`, params, isUpload);
}

function getData(path, params) {
    return GET(`${path}`, params);
}

const api = {
    //寻艺
    getXunyeePic,
    getXunyeePicDetail,
    setXunyeePicDetail,
    getXunyeeAd,
    getXunyeePicType,
    setXunyeePicType,
    setXunyeeAd,
    //公共
    getData,
    setData,
    //图表5003
    getTeleplayChart,
    getZyChart,
};
export default api
