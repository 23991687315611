/**
 * Created by rorschach on 2018/1/18.
 */
import axios from "axios";
import * as cookie from "../utils/cookie";
import * as siteConfig from '../static/fonts';
import qs from 'qs';

const baseURL = siteConfig.baseUrl;
const ajax = axios.create({
    timeout: 600000,
    baseURL
});

// 判断当前字符串是否以另外一个给定的子字符串开头，并根据判断结果返回 true 或 false。
// function isOurServer(url) {
//  return url.startsWith(baseURL);
// }

ajax.interceptors.request.use(
    config => {
        if (config.method === "post") {
            if(!config.isUpload){
                const bodyData = config.data || {};
                config.data = qs.stringify(bodyData);
            }
        }
        // if (isOurServer(config.url)) {}
        config.headers = {
            Authorization: "Bearer " + cookie.get("token"),
            "Content-Type": config.isUpload ? 'multipart/form-data' : "application/x-www-form-urlencoded"
        };
        return config;
    },
    error => {
        console.log("拦截" + error);
    }
);

export function request(config = {}) {
    let {url, method, data, params, isUpload} = config;
    method = method || "get";
    const finalConfig = {
        ...config,
        url,
        method,
        data,
        params,
        isUpload
    };
    return ajax
        .request(finalConfig)
        .then(function (response) {
            if (response.data.code === 30001) window.location.href = "/index/login";
            return {data: response.data};
        })
        .catch(function (error) {
            const {response} = error;
            // console.log("error");
            // console.log(error);
            // console.log(response);
            // 未登录的情况单独处理
            // if (response.status === 401 || response.status === 403) {
            //   //跳转登录页并清除用户信息
            //   // vlStorage.clearAll();
            //   // vlStorage.setItem("vpath", window.location.pathname);
            //   // window.location.replace("/index/login");
            // }
            return {data: {msg: "错误" + JSON.stringify(response.data?.msg)}};
        });
}

export function POST(url, data = {}, isUpload, config = {}) {
    return request({
        ...config,
        method: "post",
        url,
        data,
        isUpload
    });
}

export function GET(url, params = {}, isUpload, config = {}) {
    return request({
        ...config,
        method: "get",
        url,
        params,
        isUpload
    });
}

// export function PUT(url, data = {}, config = {}) {
//     return request({
//         ...config,
//         method: "put",
//         url,
//         data
//     });
// }
//
// export function DELETE(url, params = {}, config = {}) {
//     return request({
//         ...config,
//         method: "delete",
//         url,
//         params
//     });
// }
